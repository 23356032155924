import { Organization } from "@/models";
import { httpStore } from "@/store/typed";
import { SD_BASE_API_URL } from "@/utils/Constants";
import qs from "qs";
import { Component, Vue } from "vue-property-decorator";
export enum OrganizationTags {
  OrganizationShow = "OrganizationShow",
  OrganizationIndex = "OrganizationIndex",
  OrganizationDestroy = "OrganizationDestroy",
  OrganizationUpdate = "OrganizationUpdate",
  OrganizationCreate = "OrganizationCreate",
  OrganizationIpaData = "OrganizationIpaData",
  OrganizationSearchIpa = "OrganizationSearchIpa",
  OrganizationContact = "OrganizationContact",
}
@Component
export default class OrganizationMixin extends Vue {
  getOrganizations(page: number | null | string, filter: any) {
    const url = `${SD_BASE_API_URL}/organizations`;
    const data = {
      page: page,
      filter: filter,
    };
    return httpStore.request({
      tag: OrganizationTags.OrganizationIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<Organization[]>;
  }

  getOrganization(id: number | string) {
    const url = `${SD_BASE_API_URL}/organizations/${id}`;
    return httpStore.request({
      tag: OrganizationTags.OrganizationShow,
      url: url,
      method: "GET",
    }) as Promise<Organization>;
  }

  getIpaData(code: string) {
    const url = `${SD_BASE_API_URL}/organizations/ipa_data`;
    return httpStore.request({
      tag: OrganizationTags.OrganizationIpaData,
      url: url,
      method: "POST",
      data: { code: code },
      noParse: true,
    }) as Promise<any>;
  }

  searchIpaOrganizations(data: { term?: string; code?: string }) {
    const url = `${SD_BASE_API_URL}/organizations/search_ipa`;
    return httpStore.request({
      tag: OrganizationTags.OrganizationSearchIpa,
      url: url,
      method: "POST",
      data: data,
      noParse: true,
    }) as Promise<any>;
  }

  destroyOrganization(id: string | number) {
    const url = `${SD_BASE_API_URL}/organizations/${id}`;
    return httpStore.request({
      tag: OrganizationTags.OrganizationDestroy,
      url: url,
      method: "DELETE",
    }) as Promise<Organization>;
  }

  createOrganization(data: any) {
    const url = `${SD_BASE_API_URL}/organizations`;
    return httpStore.request({
      tag: OrganizationTags.OrganizationCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<Organization>;
  }

  updateOrganization(data: any) {
    const url = `${SD_BASE_API_URL}/organizations/${data.id}`;
    return httpStore.request({
      tag: OrganizationTags.OrganizationUpdate,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<Organization>;
  }

  contactOrganization(id: string | number, message: string) {
    const data = { message: message };
    const url = `${SD_BASE_API_URL}/organizations/${id}/contact`;

    return httpStore.request({
      tag: OrganizationTags.OrganizationContact,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<Organization>;
  }
}


import { Vue, Component, Prop } from "vue-property-decorator";
import VAccordion from "@/components/Common/VAccordion.vue";
import moment from "moment";
import { Document, Contract } from "@/models";

@Component({ components: { VAccordion } })
export default class ContractDocumentItem extends Vue {
  @Prop({ required: true }) readonly document!: Document;
  @Prop({ required: true }) readonly contract!: Contract;

  get startDate() {
    return this.contract?.startDate
      ? moment(this.contract.startDate).format("DD MMM YYYY").toUpperCase()
      : "";
  }

  get contractDetail() {
    return { name: "contract_show", params: { id: this.contract?.id } };
  }

  get endDate() {
    return this.contract?.endDate
      ? moment(this.contract.endDate).format("DD MMM YYYY").toUpperCase()
      : "";
  }

  get isMobile() {
    return this.$mq === "sm" || this.$mq === "md";
  }
}


import { Vue, Component, Prop, Mixins } from "vue-property-decorator";

import PageSection from "@/components/Common/PageSection.vue";
import VSvg from "@/components/Common/VSvg.vue";
import VAlert from "@/components/Common/VAlert.vue";
import { StrapiService } from "@/models";
import ServicesMixin from "@/mixins/http/strapi/ServiceMixin";
import ServiceBox from "@/models/strapi/ServiceBox";
import TeamMember from "@/models/strapi/TeamMember";
import Media from "@/models/strapi/Media";
import { urlFromMedia } from "@/utils/UrlHelper";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast } from "@/utils/Toast";

import marked from "marked";

@Component({
  components: {
    PageSection,
    VAlert,
  },
})
export default class ServiceShow extends Mixins(ServicesMixin) {
  private service: StrapiService | null = null;

  async created() {
    const [data, errors] = await safeAsync(
      this.getService(this.$route.params.serviceId)
    );
    if (errors) {
      showErrorToast("Sì è verificato un errore di rete");
    } else {
      this.service = data;
    }
  }

  get serviceBoxes(): ServiceBox[] | [] {
    return this.service ? this.service.service_boxes : [];
  }

  get teamMembers(): TeamMember[] | [] {
    return this.service ? this.service.team_members : [];
  }

  get solutions() {
    return marked(this.service.solutions_text);
  }

  private getTeamMemberContent(value: TeamMember) {
    if (!value || !value.content) {
      return "";
    }

    return marked(value.content);
  }

  private getIconUrl(icon: Media): string {
    return urlFromMedia(icon, "thumbnail");
  }
}

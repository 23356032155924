import { Component, Vue } from "vue-property-decorator";
import { IS_EDITION_3, STRAPI_BASE_API_URL } from "@/utils/Constants";
import { httpStore } from "@/store/typed";
import Service from "@/models/strapi/Service";
import qs from "qs";

export enum StrapiServiceTags {
  ServiceIndex = "ServicesList",
  ServiceShow = "ServiceShow",
}

@Component
export default class StrapiServiceMixin extends Vue {
  getServices(data: any): Promise<Service[]> {
    const url = IS_EDITION_3
      ? `${STRAPI_BASE_API_URL}/grouped-realms-services`
      : `${STRAPI_BASE_API_URL}/services`;
    return httpStore.request({
      tag: StrapiServiceTags.ServiceIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<Service[]>;
  }

  getService(id: any): Promise<Service> {
    const url = IS_EDITION_3
      ? `${STRAPI_BASE_API_URL}/grouped-realms-services/${id}`
      : `${STRAPI_BASE_API_URL}/services/${id}`;
    return httpStore.request({
      tag: StrapiServiceTags.ServiceIndex,
      url: url,
      method: "GET",
    }) as Promise<Service>;
  }
}

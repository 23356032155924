
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class EmptyView extends Vue {
  @Prop() readonly message!: string;
  @Prop() readonly title!: string;
  @Prop({ default: "center" }) readonly alignment!: string;

  get alignmentClass() {
    return `text-${this.alignment}`;
  }
}

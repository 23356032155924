
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class LoadingButton extends Vue {
  @Prop({ default: false }) readonly loading!: boolean;

  clickHandler() {
    this.$emit("button-click");
  }
}


import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import VAccordion from "@/components/Common/VAccordion.vue";
import { DocumentType } from "@/models/Document";
import moment from "moment";
import { Document, User } from "@/models";
import RejectModal from "@/components/Document/RejectModal.vue";
import { currentUserStore } from "../../store/typed";
import { Contract } from "../../models";
import { safeAsync } from "@/utils/AsyncUtil";
import { RegisterHttp } from "@/utils/Decorators";
import { showToast } from "@/utils/Toast";
import RequestData from "@/store/types/RequestData";
import DocumentAdminMixin, {
  DocumentAdminTags,
} from "@/mixins/http/admin/DocumentAdminMixin";
import {
  DocumentMetadata,
  RequirementPlansMetadata,
} from "@/models/DocumentMetadata";
@Component({ components: { VAccordion, RejectModal } })
export default class DocumentItem extends Mixins(DocumentAdminMixin) {
  @Prop({ required: true }) readonly document!: Document;
  @Prop({ required: true }) readonly contract!: Contract;
  @Prop({ required: false }) readonly initiativeStatus!: string;
  @Prop({ default: false }) readonly groupActive!: boolean;

  @Ref() readonly rejectModal: any;

  @RegisterHttp(DocumentAdminTags.DocumentAccept)
  readonly acceptRequest!: RequestData;

  get attachedAt(): string {
    return moment(this.document.attachedAt).format("DD MMM YYYY");
  }

  get currentUser(): User {
    return currentUserStore.currentUser;
  }

  get isExecutiveContract(): boolean {
    return this.document.documentType === DocumentType.ExecutiveContract;
  }

  get isRequirementsPlan(): boolean {
    return this.document.documentType === DocumentType.RequirementsPlan;
  }

  get loading() {
    return this.acceptRequest?.loading;
  }

  get isWorkPlan(): boolean {
    return this.document.documentType === DocumentType.WorkPlan;
  }

  get isOperativePlan(): boolean {
    return this.document.documentType === DocumentType.OperativePlan;
  }

  get isMobile(): boolean {
    return this.$mq === "sm" || this.$mq === "md";
  }

  get documentStatusClass(): string {
    return `badge-${this.document.statusClass}`;
  }

  get contractStartDate(): string {
    return moment(this.contract?.startDate).format("DD MMM YYYY").toUpperCase();
  }

  openRejetModal() {
    (this.rejectModal as any).open(this.document);
  }

  async approve() {
    const [data, errors] = await safeAsync(
      this.acceptDocument(this.document.id)
    );

    if (!errors) {
      this.$emit("load");
    } else {
      showToast("Non è stato possibile approvare il documento", {
        type: "error",
      });
    }
  }

  get canReject() {
    return (
      this.groupActive &&
      this.isToEvaluate &&
      !this.isExecutiveContract &&
      !this.isWorkPlan
    );
  }

  get canApprove() {
    return this.canReject;
  }

  get contractDetail(): { name: string; params: { id: string } } {
    return { name: "contract_show", params: { id: this.contract?.id } };
  }

  get contractEndDate(): string {
    return moment(this.contract?.endDate).format("DD MMM YYYY").toUpperCase();
  }

  get pecBody(): string {
    return (this.document.metadata as DocumentMetadata)?.pecBody || "-";
  }

  get pecSender(): string {
    return (this.document.metadata as DocumentMetadata)?.pecSender || "-";
  }

  get organizationPec(): string {
    return (
      (this.document.metadata as RequirementPlansMetadata)?.organizationPec ||
      "-"
    );
  }

  get notes(): string {
    return (this.document.metadata as RequirementPlansMetadata)?.notes || "-";
  }

  get expiring() {
    return (
      this.document.expiresIn &&
      this.document.expiresIn <= 5 &&
      this.document.type == Document.typeFromDocument(this.initiativeStatus)
    );
  }

  get isToEvaluate() {
    return this.document?.isToEvaluate;
  }

  get statusLabel() {
    return this.document.statusLabel(this.currentUser);
  }

  downloadDocument() {
    window.open(this.document.fileUrl);
  }

  get isAdministrative() {
    return currentUserStore.currentUser?.isAdministrative;
  }

  get opened() {
    return this.groupActive && this.isToEvaluate;
  }

  get pecSubject() {
    return (this.document.metadata as DocumentMetadata)?.pecSubject || "-";
  }
}

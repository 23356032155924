import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { InitiativeOrganization } from "@/models";
import { InitiativeOrganizationPayload } from "@/models/InitiativeOrganization";
import { SD_BASE_ADMIN_API_URL } from "@/utils/Constants";
export enum InitiativeOrganizationAdminTags {
  InitiativeOrganizationCreate = "InitiativeOrganizationCreate",
  InitiativeOrganizationDestroy = "InitiativeOrganizationDestroy",
}

@Component
export default class InitiativeOrganizationAdminMixin extends Vue {
  createInitiativeOrganization(
    data: InitiativeOrganizationPayload
  ): Promise<InitiativeOrganization> {
    const url = `${SD_BASE_ADMIN_API_URL}/initiative_organizations`;

    return httpStore.request({
      tag: InitiativeOrganizationAdminTags.InitiativeOrganizationCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<InitiativeOrganization>;
  }

  destroyInitiativeOrganization(id: number | string) {
    const url = `${SD_BASE_ADMIN_API_URL}/initiative_organizations/${id}`;

    return httpStore.request({
      tag: InitiativeOrganizationAdminTags.InitiativeOrganizationDestroy,
      url: url,
      method: "DELETE",
    }) as Promise<InitiativeOrganization>;
  }
}


import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { DocumentType } from "@/models/Document";
import DocumentForm from "@/components/Document/DocumentForm.vue";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import { Document } from "../../models";

@Component({
  components: {
    VModal,
    DocumentForm,
  },
})
export default class CreateDocumentModal extends Vue {
  @Prop({ required: true }) readonly initiativeId!: string;

  @Ref() readonly modal!: VModal;
  @Ref() readonly documentForm!: DocumentForm;

  private type: string | null = null;

  get requirementsPlanDocument() {
    return this.type === "requirementsPlans";
  }

  get operativePlanDocument() {
    return this.type === "operativePlans";
  }

  get workPlanDocument() {
    return this.type === "workPlans";
  }

  get executiveDocument() {
    return this.type === "executiveContracts";
  }

  get title() {
    if (this.requirementsPlanDocument) {
      return "Aggiungi piano dei fabbisogni";
    } else if (this.operativePlanDocument) {
      return "Aggiungi piano operativo";
    } else if (this.workPlanDocument) {
      return "Aggiungi piano dei lavori generale";
    }

    return "Aggiungi un documento";
  }

  show(type: DocumentType) {
    this.type = type;
    (this.modal as any).show();
  }

  hide() {
    (this.modal as any).hide();
  }

  onCreated(document: Document) {
    this.$emit("created", document);
    this.hide();
  }

  private onUpload() {
    this.documentForm.submit();
  }
}

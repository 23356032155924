
import { Vue, Component, Prop } from "vue-property-decorator";
import { propertyByName } from "@/utils/ScssHelper";

@Component
export default class VAlert extends Vue {
  @Prop({ default: "" }) readonly content!: string;
  @Prop({ default: "primary" }) readonly borderColor!: string;
  @Prop({ default: "custom-gray-100" }) readonly backgroundColor!: string;

  get backgroundClass() {
    const color = propertyByName(this.backgroundColor);
    return { backgroundColor: color };
  }

  get borderClass() {
    const color = propertyByName(this.borderColor);
    return { borderLeft: `2px solid ${color}` };
  }
}

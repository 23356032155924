
import { Component, Prop, Ref, Mixins } from "vue-property-decorator";
import OrganizationSelect from "@/components/Organization/Select.vue";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import InitiativeOrganizationAdminMixin, {
  InitiativeOrganizationAdminTags,
} from "@/mixins/http/admin/InitiativeOrganizationAdminMixin";
import LoadingButton from "@/components/Common/LoadingButton.vue";
import { Initiative, InitiativeOrganization } from "../../models";
import { safeAsync } from "@/utils/AsyncUtil";
import { RegisterHttp } from "@/utils/Decorators";
import { showToast } from "@/utils/Toast";
import RequestData from "@/store/types/RequestData";
import Initiatives from "../ProjectManagement/Initiatives.vue";
@Component({
  components: { LoadingButton, OrganizationSelect, VModal },
})
export default class InitiativeOrganizations extends Mixins(
  InitiativeOrganizationAdminMixin
) {
  @Ref() readonly modal!: VModal;
  @Prop({ required: true }) readonly organizations!: InitiativeOrganization[];
  @Prop({ default: false }) readonly loading!: boolean;
  @Prop({ required: true }) readonly initiative!: Initiative;
  @Prop({ required: true }) readonly realmId!: string[];
  @Prop() readonly icon!: string;

  @RegisterHttp(InitiativeOrganizationAdminTags.InitiativeOrganizationCreate)
  readonly createRequest!: RequestData;
  @RegisterHttp(InitiativeOrganizationAdminTags.InitiativeOrganizationDestroy)
  readonly destroyrequest!: RequestData;

  private organizationId: string | null = null;

  get initiativeId() {
    return this.initiative?.id;
  }

  async destroy(id: string) {
    const [data, error] = await safeAsync(
      this.destroyInitiativeOrganization(id)
    );
    if (!error) {
      showToast("PA rimossa dall'iniziativa");
      this.$emit("destroyed", id);
      this.hide();
    } else {
      showToast("Non è stato possibile rimuovere la PA", { type: "error" });
    }
  }

  showModal(): void {
    (this.modal as any).show();
  }

  get hasOrganizations() {
    return this.organizations?.length;
  }

  hide(): void {
    (this.modal as any).hide();
  }

  async create() {
    const payload = {
      organizationId: this.organizationId,
      initiativeId: this.initiativeId,
    };

    const [data, error] = await safeAsync(
      this.createInitiativeOrganization(payload)
    );
    if (!error) {
      showToast("PA associata all'iniziativa");
      this.$emit("created", data);
      this.hide();
    } else {
      showToast("Non è stato possibile aggiungere la PA", { type: "error" });
    }
  }

  organizationSelect(id: string): void {
    this.organizationId = id;
  }

  get excludedIds(): string[] | number[] {
    const alreadySelectedIds = this.organizations.map(
      (u: InitiativeOrganization) => u.organization.id
    );
    return [...alreadySelectedIds, this.initiative.organization.id];
  }

  get organizationSelectFilter() {
    return {
      realm: this.realmId,
      not: this.excludedIds,
    };
  }
}

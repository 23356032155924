
import { Component, Mixins } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import {
  Contract,
  Initiative,
  InitiativeOrganization,
  RequirementsPlan,
} from "../../models";
import DocumentGroupItem from "@/components/Document/Group.vue";
import Document, { DocumentGroup } from "@/models/Document";
import InitiativeMixin from "@/mixins/http/InitiativeMixin";
import InitiativeAdminMixin from "@/mixins/http/admin/InitiativeAdminMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import Organizations from "@/components/Initiative/Organizations.vue";
import { httpStore } from "@/store/typed";
import { camelToSnake } from "@/utils/stringUtils";
import EmptyView from "@/components/Common/EmptyView.vue";
import { currentUserStore } from "../../store/typed";
import { showToast } from "@/utils/Toast";
import DocumentModal from "@/components/Document/CreateModal.vue";
import DocumentAdminMixin from "@/mixins/http/admin/DocumentAdminMixin";
@Component({
  components: {
    PageSection,
    EmptyView,
    Organizations,
    DocumentModal,
    DocumentGroupItem,
  },
})
export default class InitiativeShow extends Mixins(
  InitiativeMixin,
  InitiativeAdminMixin,
  DocumentAdminMixin
) {
  private initiative: Initiative | null = null;
  private error = false;
  private buttonPopup = false;

  private documentSections: { key: string; label: string }[] = [
    { key: "workPlans", label: "Piano di lavoro" },
    { key: "executiveContracts", label: "Contratto esecutivo" },
    { key: "operativePlans", label: "Piano operativo" },
    { key: "requirementsPlans", label: "Piano dei fabbisogni" },
  ];

  mounted() {
    this.load();
  }
  get id(): string {
    return this.$route.params.id;
  }

  get stepClass() {
    return `badge-${this.initiative.stepClass}`;
  }

  onOrganizationCreated(item: InitiativeOrganization) {
    this.initiative.initiativeOrganizations.push(item);
  }

  onOrganizationDestroyed(id: string) {
    const index = this.initiative.initiativeOrganizations.findIndex(
      (o: InitiativeOrganization) => o.id === id
    );
    this.initiative.initiativeOrganizations.splice(index, 1);
  }

  async close() {
    const [data, errors] = await safeAsync(this.closeInitiative(this.id));
    if (!errors) {
      showToast("Iniziativa chiusa");
      this.error = false;
      this.initiative.status = data.status;
      this.initiative.step = data.step;
    } else {
      this.error = true;
    }
  }

  get lastCompleteRequirementsPlan(): RequirementsPlan {
    return this.initiative.requirementsPlans.find(
      (o: Document) => o.status !== "rejected"
    );
  }

  get anyDocument(): number {
    return (
      this.initiative?.workPlans?.length ||
      this.initiative?.requirementsPlans?.length ||
      this.initiative?.executiveContracts?.length ||
      this.initiative?.operativePlans?.length
    );
  }

  get contract(): Contract {
    return this.initiative?.contract;
  }

  documentGroup(section: { key: string; label: string }): DocumentGroup {
    const documents = (this.initiative as any)[section.key] as Document[];

    if (!documents?.length) {
      return null;
    }

    return {
      documents: (this.initiative as any)[section.key] as Document[],
      label: section.label,
      key: section.key,
      active:
        camelToSnake(section.key) === camelToSnake(this.initiative.status),
    };
  }

  get isAdministrative(): boolean {
    return currentUserStore.currentUser?.isAdministrative;
  }

  get showUploadButton(): any {
    return (
      this.isAdministrative &&
      !this.initiative?.isClosed &&
      this.documentsButtonLabel
    );
  }

  performAction() {
    if (this.initiative?.createdStep) {
      return this.addDocument("requirementsPlans");
    } else if (this.initiative?.requirementsPlansStep) {
      if (this.isLastRejected(this.initiative?.requirementsPlans as any)) {
        return this.addDocument("requirementsPlans");
      } else {
        return this.addDocument("operativePlans");
      }
    } else if (this.initiative?.operativePlansStep) {
      if (this.isLastRejected(this.initiative?.operativePlans as any)) {
        return this.addDocument("operativePlans");
      } else {
        this.$router.push({ name: "admin_contract_create" });
      }
    } else if (this.initiative?.executiveContractsStep) {
      return this.addDocument("workPlans");
    }
  }

  isLastRejected(documents: Document[]) {
    const lastOne = documents[0];
    return lastOne.isRejected;
  }

  isLastToEvaluate(documents: Document[]) {
    const lastOne = documents[0];
    return lastOne.isToEvaluate;
  }

  get buttonDisabled() {
    if (this.initiative?.createdStep) {
      return false;
    } else if (this.initiative?.requirementsPlansStep) {
      return this.isLastToEvaluate(this.initiative?.requirementsPlans as any);
    } else if (this.initiative?.operativePlansStep) {
      return this.isLastToEvaluate(this.initiative?.operativePlans as any);
    } else {
      return false;
    }
  }

  get documentsButtonLabel(): string | undefined {
    if (this.initiative?.createdStep) {
      return "Aggiungi Piano dei Fabbisogni";
    } else if (this.initiative?.requirementsPlansStep) {
      if (this.isLastRejected(this.initiative?.requirementsPlans as any)) {
        return "Aggiungi Piano dei Fabbisogni";
      } else {
        return "Aggiungi Piano Operativo";
      }
    } else if (this.initiative?.operativePlansStep) {
      if (this.isLastRejected(this.initiative?.operativePlans as any)) {
        return "Aggiungi Piano Operativo";
      } else {
        return "Crea Contratto Esecutivo";
      }
    }
    return undefined;
  }

  get initiativeTitle(): string | undefined {
    return this.initiative?.name;
  }

  get organizationName(): string | undefined {
    return this.initiative?.organization?.name;
  }

  async load() {
    const [data, errors] = await safeAsync(this.getInitiative(this.id));
    if (!errors) {
      this.initiative = data;
      this.error = false;
    } else {
      this.error = true;
    }
  }

  get loading(): boolean {
    return httpStore.status["CloseInitiative"]?.loading;
  }

  get documentModal() {
    return this.$refs[this.ref];
  }

  addDocument(type: string) {
    (this.documentModal as any).show(type);
  }

  get ref() {
    return `${this.initiative.status}_document_modal`;
  }
}

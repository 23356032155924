
import { Component, Ref, Mixins } from "vue-property-decorator";
import DocumentForm from "@/components/Document/DocumentForm.vue";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import { Document } from "../../models";
import DocumentAdminMixin, {
  DocumentAdminTags,
} from "@/mixins/http/admin/DocumentAdminMixin";
import VFileUploader from "@/components/Common/VFileUploader.vue";
import { showToast } from "@/utils/Toast";
import { safeAsync } from "@/utils/AsyncUtil";
import WithErrors from "@/directives/WithErrors";
import RequestData from "@/store/types/RequestData";
import { RegisterHttp } from "@/utils/Decorators";
@Component({
  components: {
    VModal,
    VFileUploader,
    DocumentForm,
  },
  directives: {
    WithErrors,
  },
})
export default class DocumentEstimateModal extends Mixins(DocumentAdminMixin) {
  @RegisterHttp(DocumentAdminTags.DocumentReject)
  rejectRequest!: RequestData;

  private file: any = null;

  @Ref() readonly modal!: VModal;
  private document: Document | null = null;

  open(document: Document) {
    this.document = Object.assign(new Document(), document) as Document;
    (this.modal as any).show();
  }

  get fileName() {
    return this.file ? this.file.name : "";
  }

  hide() {
    (this.modal as any).hide();
  }

  onEstimated(document: Document) {
    this.$emit("rejected", document);
    this.hide();
  }

  async reject() {
    const form = new FormData();

    const payload = {
      rejectionMessage: this.document.rejectionMessage,
      rejectionSentAt: this.document.rejectionSentAt,
    };

    const [data, errors] = await safeAsync(
      this.rejectDocument(this.document?.id, payload)
    );
    if (!errors) {
      this.onEstimated(data);
      showToast("Documento marcato come non conforme");
    } else {
      showToast("Non è stato possibile eseguire l'operazione", {
        type: "error",
      });
    }
  }

  get loading() {
    return this.rejectRequest?.loading;
  }

  get httpTag() {
    return DocumentAdminTags.DocumentReject;
  }

  get documentName() {
    return this.document.documentName;
  }

  get title() {
    return `Segnala ${this.document?.documentName || "documento"} non conforme`;
  }
}


import { Component, Prop, Mixins } from "vue-property-decorator";
import Document, { DocumentGroup } from "../../models/Document";
import DocumentItem from "@/components/Document/Item.vue";
import ContractDocumentItem from "@/components/Document/ContractItem.vue";
import { Contract, Initiative } from "@/models";
import DocumentModal from "@/components/Document/CreateModal.vue";
import { currentUserStore } from "../../store/typed";
import DocumentAdminMixin from "@/mixins/http/admin/DocumentAdminMixin";

@Component({
  components: { ContractDocumentItem, DocumentItem, DocumentModal },
})
export default class DocumentGroupItem extends Mixins(DocumentAdminMixin) {
  @Prop({ required: true }) readonly group!: DocumentGroup;
  @Prop({ required: true }) readonly contract!: Contract;
  @Prop({ required: true }) readonly initiativeId!: string;
  @Prop({ required: true }) readonly initiative!: Initiative;

  showHistory = false;

  get documents() {
    return (
      (this.showHistory
        ? this.group.documents
        : [
            this.group.documents.find((o: Document) => o.status !== "closed"),
          ]) || []
    );
  }

  get type() {
    return this.group.key;
  }

  get isAdministrative() {
    return currentUserStore.currentUser?.isAdministrative;
  }

  get isExecutiveContract() {
    return this.group.key === "executiveContracts";
  }

  onDocumentCreated(document: Document) {
    this.$emit("reload");
  }

  get documentModal() {
    return this.$refs[this.ref];
  }

  addDocument() {
    (this.documentModal as any).show(this.type);
  }

  get ref() {
    return `${this.type}_document_modal`;
  }
}
